import React, { useState, useMemo, useEffect } from "react"
// import { Router, Link } from "@reach/router"
import { useStaticQuery } from "gatsby"
import { get } from "lodash"
import { graphql } from "gatsby"
import { linkResolver } from "../utils/linkResolver"
import { Strings } from "../utils/strings"
import LogoImg from "../images/logo.svg"
import { getLanguageNameWithCountry } from "language-name-to-language-name"

// import TransitionLink from "gatsby-plugin-transition-link"
// import AniLink from "gatsby-plugin-transition-link/AniLink"

// https://github.com/koniecznytomek/gatsby-transition-links

// import { AnchorLink } from 'gatsby-plugin-anchor-links'
// import Modal from 'react-modal'
// import { useWindowSizes } from '@hooks/window'

const Header = ({ pathname, isHome, meta }) => {
  // console.log('pathname', pathname)

  const currentLang = meta.lang || "en-us"
  const alternateLang = meta.alternateLanguages || []
  // console.log('alternateLang', alternateLang)

  const [isOpen, setIsOpen] = useState(true)
  const [isOpenClass, setIsOpenClass] = useState("")

  useEffect(() => {
    // MOBILE HEIGHT

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`)

    // We listen to the resize event
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    })

    // SET WEBSITE LANGUAGE ON FIRST LOAD
    // const browserLanguage = navigator.language.includes('-') ? navigator.language.toLowerCase().split('-')[0] : navigator.language.toLowerCase()
    const browserLanguage = "pt"

    // console.log('browserLanguage', browserLanguage)

    if (!window.localStorage.getItem("userLanguage")) {
      let userLang = ""

      if (currentLang.includes(browserLanguage)) {
        window.localStorage.userLanguage = currentLang
      } else {
        userLang = alternateLang.find(lang =>
          lang.lang.includes(browserLanguage)
        )
        userLang =
          userLang?.lang !== null && userLang !== undefined
            ? userLang.lang
            : currentLang
        window.localStorage.userLanguage = userLang
      }

      haddleChangeLanguage()
    }

    // Home Scroll Effect
    window.addEventListener("scroll", handleScroll)
  })

  const data = useStaticQuery(query)

  const allStrings = Strings(currentLang)

  // console.log('currentLang', currentLang)

  const edges = get(data, "prismic.allNavigations.edges")

  // console.log('[header.js] data', data)

  let edge
  edges.map((item, index) => {
    const edge_lang = get(item, "node._meta.lang")
    // console.log('[header.js] edges.map', index, item, edge_lang)
    if (currentLang === edge_lang) {
      edge = item
    }

    return null
  })

  // const { isIpadPro } = useWindowSizes()

  const items = useMemo(() => get(edge, "node.body") || [], [edge])
  const lang = useMemo(() => get(edge, "node._meta.lang"), [edge])
  // const email = useMemo(() => get(edge, 'node.email[0].text') || [], [edge])
  // console.log('[header.js] edge', edge)

  const toggleClassHandler = () => {
    if (isOpen === true) {
      setIsOpenClass(" open")
    } else {
      setIsOpenClass("")
    }
  }

  const toggleMenuHandler = () => {
    // console.log('[header.js] toggleMenuHandler clicked')
    // console.log('[header.js] isOpen', isOpen)

    // Toggle state
    setIsOpen(!isOpen)
    // console.log('[header.js] isOpen after', isOpen)
    toggleClassHandler()
  }

  const renderItem = (item, index, position) => {
    const text = get(item, "primary.link_name[0].text")

    const linkUrlType = get(item, "primary.link_url._linkType")
    let linkUrl = ""
    if (linkUrlType === "Link.document") {
      linkUrl = linkResolver(get(item, "primary.link_url"), lang)
    } else {
      linkUrl = get(item, "primary.link_url.url")
    }
    const type = get(item, "type")
    const position_type = type === "link" ? "left" : "right"
    let active_class = ""
    if (linkUrl === pathname) {
      active_class = " active"
    }

    // const linktarget = get(item, 'primary.link_url.target', '_self')

    if (position !== position_type) {
      return null
    }

    return (
      <li key={index}>
        <a href={linkUrl} className={"btn-secondary" + active_class}>
          {text}
        </a>
      </li>
    )
  }

  // Toggle Lang Button

  const [langButtonState, setLangButtonState] = useState("")
  const toggleLangButton = () => {
    // setSortButtonState(!sortButtonState)
    if (langButtonState === "") {
      setLangButtonState(" open")
    } else {
      setLangButtonState("")
    }
  }

  // Haddle Change language

  const haddleChangeLanguage = lang => {
    // console.log('haddleChangeLanguage', e?.target.value)

    const targetLanguage = lang ?? window.localStorage.getItem("userLanguage")
    if (targetLanguage === currentLang) return null

    window.localStorage.userLanguage = targetLanguage

    const target_path = linkResolver(meta, targetLanguage)
    window.location.href = target_path
  }
  // const haddleChangeLanguage = (lang) => {
  //   // console.log('haddleChangeLanguage', e?.target.value)

  //   const target = lang ?? window.localStorage.getItem("userLanguage")
  //   if (target === currentLang)
  //     return null

  //   //  console.log('target', target)

  //   window.localStorage.userLanguage = target

  //   let target_path

  //   if(target === 'en-us') {
  //     pathname_split.splice(1, 1)
  //     target_path = pathname_split.map(element=>element).join("/")
  //     // console.log('target_path', target_path)
  //     window.location.href = target_path
  //   }

  //   if(target === 'pt-br' || target === 'de-de' ) {
  //     if(currentLang !== 'en-us') {
  //       pathname_split.splice(1, 1)
  //     }

  //     target_path = '/' + target + pathname_split.map(element=>element).join("/")
  //     // console.log('target_path', target_path)
  //     window.location.href = target_path
  //   }
  // }

  // Home Scroll Effect

  const [homeShow, setHomeShow] = useState("")
  const handleScroll = () => {
    // console.log('document.body.scrollTop ', document.documentElement.scrollTop )

    if (372 > document.documentElement.scrollTop) {
      setHomeShow("")
    } else {
      setHomeShow(" home-show")
    }
  }

  let lang_url = currentLang !== "en-us" ? currentLang : ""
  let headerClass = isHome
    ? "main_header main_header-home" + homeShow
    : "main_header"

  return (
    <header className={"img_w " + headerClass}>
      <div className="row main_header__row">
        <div className="col col12 main_header__container">
          <div className="main_header__menu_items">
            <ul className="main_header__menu_items__left hidden_ipadPro">
              {items.map((item, index) => renderItem(item, index, "left"))}
            </ul>
            <div className="main_header__logo">
              <a href={"/" + lang_url}>
                <img src={LogoImg} alt="Ocafi" />
              </a>
            </div>
            <ul className="main_header__menu_items__right hidden_ipadPro">
              {items.map((item, index) => renderItem(item, index, "right"))}
            </ul>
          </div>
          <div
            //className={"main_header__mobile__burger visible_ipad"+isOpenClass}
            className={
              "main_header__mobile__btn btn-secondary white visible_ipadPro" +
              isOpenClass
            }
            onClick={toggleMenuHandler}
            onKeyDown={toggleMenuHandler}
            role="button"
            tabIndex="0"
          >
            {isOpen ? allStrings?.menu ?? "Menu" : allStrings?.close ?? "Close"}
          </div>
        </div>
      </div>

      <div className={"main_header__mobile_nav__box" + isOpenClass}>
        <div className={"main_header__mobile_nav" + isOpenClass}>
          <div className="main_header__mobile_nav__container">
            <div className="main_header__mobile_nav__links">
              <ul className="p-large p-large-medium">
                {items.map((item, index) => renderItem(item, index, "left"))}
                {items.map((item, index) => renderItem(item, index, "right"))}
              </ul>
            </div>

            {alternateLang.length > 0 && (
              <div
                className={
                  "btn-dropdown main_header__btn_language arrow-rev" +
                  langButtonState
                }
                onClick={() => toggleLangButton()}
                onKeyDown={() => toggleLangButton()}
                role="button"
                tabIndex="0"
              >
                <div className="btn-dropdown__name">
                  {getLanguageNameWithCountry(currentLang, "en").native}
                </div>

                <ul className="btn-dropdown__items">
                  <li className={"active"}>
                    <button>
                      <span className="btn-dropdown__items__square"></span>
                      {getLanguageNameWithCountry(currentLang, "en").native}
                    </button>
                  </li>

                  {alternateLang.map((altLang, index) => (
                    <li key={index}>
                      <button
                        onClick={() => haddleChangeLanguage(altLang.lang)}
                      >
                        <span className="btn-dropdown__items__square"></span>
                        {getLanguageNameWithCountry(altLang.lang, "en").native}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}

const query = graphql`
  query($lang: String) {
    prismic {
      allNavigations(uid: "navigation", lang: $lang) {
        edges {
          node {
            _meta {
              lang
              type
              uid
            }
            body {
              ... on PRISMIC_NavigationBodyLink {
                label
                type
                primary {
                  link_name
                  link_url {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      target
                      url
                    }
                    ... on PRISMIC__Document {
                      _meta {
                        lang
                        type
                        uid
                      }
                    }
                  }
                }
              }

              ... on PRISMIC_NavigationBodyLink_1 {
                label
                type
                primary {
                  link_name
                  link_url {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      target
                      url
                    }
                    ... on PRISMIC__Document {
                      _meta {
                        lang
                        type
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Header
