import React, { useMemo, useState, useEffect } from "react"
import { useStaticQuery } from "gatsby"
import { get } from "lodash"
// import Modal from 'react-modal'
import { graphql } from "gatsby"
// import AniLink from 'gatsby-plugin-transition-link/AniLink'

import { linkResolver } from "@prismicio/gatsby-source-prismic-graphql"
import LogoImg from "../images/logo_vanila.svg"
import { getLanguageNameWithCountry } from "language-name-to-language-name"

// import { useWindowSizes } from '@hooks/window'

const Footer = ({ pathname, meta }) => {
  // const { isIpadPro } = useWindowSizes()
  // console.log(' Footer pathname', pathname)

  // Current Language

  const currentLang = meta.lang || "en-gb"
  const alternateLang = meta.alternateLanguages || []
  // console.log('alternateLang', alternateLang)

  // Get Footer Data

  const data = useStaticQuery(query)
  const edges = get(data, "prismic.allNavigations.edges")

  const edge = edges.filter(item => {
    const edge_lang = get(item, "node._meta.lang")
    if (currentLang === edge_lang) {
      return item
    } else {
      return null
    }
  })[0]

  let footer_text = useMemo(() => get(edge, "node.footer_text[0].text") || [], [
    edge,
  ])
  const current_year = new Date().getFullYear()
  footer_text = footer_text.replace("$current_year", current_year)
  const big_links = useMemo(() => get(edge, "node.big_links") || [], [edge])
  const main_pages = useMemo(() => get(edge, "node.main_pages") || [], [edge])
  const social_media = useMemo(() => get(edge, "node.social_media") || [], [
    edge,
  ])
  const legal_pages = useMemo(() => get(edge, "node.legal_pages") || [], [edge])

  // Toggle Lang Button

  const [langButtonState, setLangButtonState] = useState("")
  const toggleLangButton = () => {
    // setSortButtonState(!sortButtonState)
    if (langButtonState === "") {
      setLangButtonState(" open")
    } else {
      setLangButtonState("")
    }
  }

  // Haddle Change Language

  const haddleChangeLanguage = lang => {
    // console.log('haddleChangeLanguage', e?.target.value)

    const targetLanguage = lang ?? window.localStorage.getItem("userLanguage")
    if (targetLanguage === currentLang) return null

    window.localStorage.userLanguage = targetLanguage

    const target_path = linkResolver(meta, targetLanguage)
    window.location.href = target_path
  }
  // const haddleChangeLanguage = (lang) => {
  //   // console.log('haddleChangeLanguage', e?.target.value)

  //   const target = lang ?? window.localStorage.getItem("userLanguage")
  //   if (target === currentLang)
  //     return null

  //   //  console.log('target', target)

  //   window.localStorage.userLanguage = target

  //   let target_path

  //   if(target === 'en-us') {
  //     pathname_split.splice(1, 1)
  //     target_path = pathname_split.map(element=>element).join("/")
  //     // console.log('target_path', target_path)
  //     window.location.href = target_path
  //   }

  //   if(target === 'pt-br' || target === 'de-de' ) {
  //     if(currentLang !== 'en-us') {
  //       pathname_split.splice(1, 1)
  //     }

  //     target_path = '/' + target + pathname_split.map(element=>element).join("/")
  //     // console.log('target_path', target_path)
  //     window.location.href = target_path
  //   }
  // }

  const renderBigLinks = (item, index) => {
    const text = get(item, "title[0].text")
    const page = get(item, "link")
    const link_target = get(item, "link_url.target", "_self")
    const linkUrl = linkResolver(page, currentLang)

    return (
      <li key={index} className="h2">
        <a href={linkUrl} target={link_target}>
          {text}
        </a>
      </li>
    )
  }

  const renderFooterLinks = (item, index) => {
    const text = get(item, "title[0].text")
    const page = get(item, "link")
    const link_target = get(item, "link_url.target", "_self")
    const linkUrl = linkResolver(page, currentLang)

    return (
      <li key={index}>
        <a href={linkUrl} target={link_target} className="font-book p-large">
          {text}
        </a>
      </li>
    )
  }

  const renderSocialLinks = (item, index) => {
    const text = get(item, "title[0].text")
    const page = get(item, "link")
    const link_target = get(item, "link_url.target", "_self")
    const linkUrl = linkResolver(page, currentLang)

    return (
      <li key={index}>
        <a href={linkUrl} target={link_target} className="font-book p-large">
          {text}
          <sup>TM</sup>
        </a>
      </li>
    )
  }

  const [footerClass, setFooterClass] = useState("")

  useEffect(() => {
    setFooterClass(" main_footer-load")
  }, [])

  return (
    <footer className={"main_footer img_w " + footerClass}>
      <div className="noise"></div>
      <div className="row">
        <div className="col col2 hidden_ipad"></div>
        <div className="col col6 main_footer__top_links">
          <ul>{big_links.map((item, index) => renderBigLinks(item, index))}</ul>
        </div>
        <div className="col col2 main_footer__col_btn_language hidden_iphonePlus">
          {alternateLang.length > 0 && (
            <div
              className={
                "btn-dropdown main_footer__btn_language color-rev" +
                langButtonState
              }
              onClick={() => toggleLangButton()}
              onKeyDown={() => toggleLangButton()}
              role="button"
              tabIndex="0"
            >
              <div className="btn-dropdown__name">
                {getLanguageNameWithCountry(currentLang, "en").native}
              </div>

              <ul className="btn-dropdown__items">
                <li className={"active"}>
                  <button>
                    <span className="btn-dropdown__items__square"></span>
                    {getLanguageNameWithCountry(currentLang, "en").native}
                  </button>
                </li>

                {alternateLang.map((altLang, index) => (
                  <li key={index}>
                    <button onClick={() => haddleChangeLanguage(altLang.lang)}>
                      <span className="btn-dropdown__items__square"></span>
                      {getLanguageNameWithCountry(altLang.lang, "en").native}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col col2 hidden_ipad"></div>
        <div className="col col9 main_footer__links">
          <ul className="main_footer__links__ul">
            {main_pages.map((item, index) => renderFooterLinks(item, index))}
          </ul>
          <ul className="main_footer__links__ul">
            {social_media.map((item, index) => renderSocialLinks(item, index))}
          </ul>
          <ul className="main_footer__links__ul">
            {legal_pages.map((item, index) => renderFooterLinks(item, index))}
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col col2 hidden_ipad"></div>
        <div className="col col9 font-book p-medium main_footer__copy">
          <p className="">{footer_text}</p>
        </div>

        <div className="col col12 visible_iphonePlus main_footer__col_btn_language">
          {alternateLang.length > 0 && (
            <div
              className={
                "btn-dropdown main_footer__btn_language arrow-rev color-rev" +
                langButtonState
              }
              onClick={() => toggleLangButton()}
              onKeyDown={() => toggleLangButton()}
              role="button"
              tabIndex="0"
            >
              <div className="btn-dropdown__name">
                {getLanguageNameWithCountry(currentLang, "en").native}
              </div>

              <ul className="btn-dropdown__items">
                <li className={"active"}>
                  <button>
                    <span className="btn-dropdown__items__square"></span>
                    {getLanguageNameWithCountry(currentLang, "en").native}
                  </button>
                </li>

                {alternateLang.map((altLang, index) => (
                  <li key={index}>
                    <button onClick={() => haddleChangeLanguage(altLang.lang)}>
                      <span className="btn-dropdown__items__square"></span>
                      {getLanguageNameWithCountry(altLang.lang, "en").native}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col col12 main_footer__logo">
          <img src={LogoImg} alt=" Ocafi" />
        </div>
      </div>

      <script
        async
        defer
        src="https://static.cdn.prismic.io/prismic.js?new=true&repo=ocafi"
      ></script>
    </footer>
  )
}

const query = graphql`
  query($lang: String) {
    prismic {
      allNavigations(uid: "navigation", lang: $lang) {
        edges {
          node {
            _meta {
              uid
              lang
            }
            footer_text
            big_links {
              title
              link {
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
                ... on PRISMIC__Document {
                  _meta {
                    lang
                    type
                    uid
                  }
                }
              }
            }
            legal_pages {
              title
              link {
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
                ... on PRISMIC__Document {
                  _meta {
                    lang
                    type
                    uid
                  }
                }
              }
            }
            main_pages {
              title
              link {
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
                ... on PRISMIC__Document {
                  _meta {
                    lang
                    type
                    uid
                  }
                }
              }
            }
            social_media {
              title
              link {
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
                ... on PRISMIC__Document {
                  _meta {
                    lang
                    type
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export default Footer
