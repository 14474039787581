import React, { useMemo, useEffect, useState } from "react"
import { get } from "lodash"
import { useStaticQuery } from "gatsby"
import { graphql } from "gatsby"
// import { RichText } from 'prismic-reactjs'
import { linkResolver } from "@prismicio/gatsby-source-prismic-graphql"
// import { linkResolver } from '../../utils/linkResolver'

import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

// import '../../../node_modules/uikit/dist/js/uikit.js'
// import { useWindowSizes } from '@hooks/window'

const CookieBar = ({ pathname }) => {
  const location = useLocation()

  let currentLang = ""
  let pathname_split = pathname.split("/")

  if (pathname_split[1] === "pt-br") {
    currentLang = "pt-br"
  } else if (pathname_split[1] === "de-de") {
    currentLang = "de-de"
  } else {
    currentLang = "en-us"
  }

  const data = useStaticQuery(query)

  const edges = useMemo(() => get(data, "prismic.allNavigations.edges"), [data])

  const edge = edges.filter(item => {
    const edge_lang = get(item, "node._meta.lang")
    if (currentLang === edge_lang) {
      return item
    } else {
      return null
    }
  })[0]

  //  console.log('[CookieBar.js] edge', edge)

  // Get data

  const title = useMemo(() => get(edge, "node.cookie_title[0].text"), [edge])

  const message = useMemo(() => get(edge, "node.cookie_text[0].text"), [edge])

  const cookie_accept_button = useMemo(
    () => get(edge, "node.cookie_accept_button[0].text"),
    [edge]
  )

  const cookie_manage_button = useMemo(
    () => get(edge, "node.cookie_manage_button[0].text"),
    [edge]
  )

  const cookie_link_meta = useMemo(() => get(edge, "node.cookie_manage_link"), [
    edge,
  ])

  let cookieLinkMeta =
    cookie_link_meta != null
      ? cookie_link_meta
      : edges[0].node?.cookie_manage_link

  // console.log('cookie_link_meta', cookieLinkMeta)

  const cookieUrl = linkResolver(cookieLinkMeta, currentLang)

  // Functions

  const [cookieBar, setCookieBar] = useState("")

  const startAnalitics = () => {
    window.localStorage.cookieAnalitics = "true"
    initializeAndTrack(location)
  }

  const acceptAllCookies = () => {
    window.localStorage.cookiePolicieOcafi = "true"
    setCookieBar("")
    startAnalitics()
  }
  const hideBar = () => {
    window.localStorage.cookiePolicieOcafi = "true"
    setCookieBar("")
  }
  const showBar = () => {
    window.localStorage.cookiePolicieOcafi = "false"
    setCookieBar(" show")
  }

  // Read Cookie

  useEffect(() => {
    if (window.localStorage.getItem("cookiePolicieOcafi") === "true") {
      hideBar()
    } else {
      showBar()
    }

    const runSalesViewerTraking = () => {
      const s = document.createElement("script"),
        tag = document.getElementsByTagName("script")[0]
      s.src = "https://slsntllgnc.com/stm.js?id=P1L0u4k7F6y6"
      s.async = true
      s.referrerPolicy = "no-referrer-when-downgrade"
      tag.parentNode.insertBefore(s, tag)
    }

    // Medium article for cookies
    // https://medium.com/geekculture/privacy-friendly-analytics-in-gatsbyjs-daaa2935518f

    if (window.localStorage.getItem("cookieAnalitics") === "true") {
      initializeAndTrack(location)
      runSalesViewerTraking()
      document.cookie = "gatsby-gdpr-google-analytics=true"
      document.cookie = "gatsby-gdpr-google-tagmanager=true"
    } else {
      document.cookie = "gatsby-gdpr-google-analytics=false"
      document.cookie = "gatsby-gdpr-google-tagmanager=false"
    }
  })

  return (
    <section className={"cookie_bar img_w " + cookieBar}>
      <h4>{title}</h4>

      <p className="p-medium font-book">{message}</p>

      <button
        onClick={() => {
          acceptAllCookies()
        }}
        className="cookie_bar__accept btn-secondary black"
      >
        {cookie_accept_button}
      </button>

      <a
        href={cookieUrl}
        // onClick={hideBar}
        className="cookie_bar__manage btn-secondary no_bg"
      >
        {cookie_manage_button}
      </a>
    </section>
  )
}

const query = graphql`
  query CookieBar($lang: String) {
    prismic {
      allNavigations(lang: $lang) {
        edges {
          node {
            _meta {
              lang
              uid
            }
            cookie_title
            cookie_text
            cookie_accept_button
            cookie_manage_button
            cookie_manage_link {
              ... on PRISMIC__ExternalLink {
                target
                _linkType
                url
              }
              ... on PRISMIC__Document {
                _meta {
                  lang
                  type
                  uid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default CookieBar
