// import React, { useMemo } from 'react'
import { useStaticQuery } from "gatsby"
import { get } from "lodash"

export const Strings = lang => {
  // console.log('Strings lang', lang)

  let currentLang = lang?._meta?.lang ?? lang?.lang ?? lang ?? "en-us"

  const data = useStaticQuery(queryAllStrings)
  const edges = get(data, "prismic.allStringss.edges")

  let node
  edges.map(item => {
    const edge_lang = get(item, "node._meta.lang")
    // console.log('[header.js] edges.map', index, item, edge_lang)
    if (currentLang === edge_lang) {
      node = item?.node
    }

    return null
  })

  return node
}

const queryAllStrings = graphql`
  query stringsQuery {
    prismic {
      allStringss {
        edges {
          node {
            _meta {
              lang
            }
            close
            menu
            main_no_results_found
            family_graph_body
            family_graph_bitter
            family_graph_aroma
            family_finish
            family_graph_acidity
            coffee_variety
            # coffee_status
            coffee_process
            # coffee_packaging
            # coffee_minimum_order
            coffee_harvest_year
            # coffee_current_location
            # coffee_bags_available
            coffee_altitude
            cards_product_order_sample
            cards_fazenda_card_button
            cards_product_card_button
            cards_family_card_button
            plp_sort_by
            plp_refine
            main_contact_us
            plp_refine_dropdown_all_coffees
            plp_refine_dropdown_family
            flp_sort_by
            flp_country
            flp_all_fazendas
            article_share
            legal_current_location
          }
        }
      }
    }
  }
`
